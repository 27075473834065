<template>
  <div>
    <b-container class="container-box">
      <b-row class="no-gutters">
        <b-col>
          <h1 class="font-weight-bold header-main text-uppercase mb-3">
            ติดต่อเรา
          </h1>
        </b-col>
      </b-row>

      <div class="bg-white p-3">
        <form>
          <b-row class="pl-1">
            <b-col cols="6">
              <div class="panel">
                <b-form-checkbox
                  size="lg"
                  id="sameLang"
                  class="mt-0 mt-sm-3"
                  @change="useSameLanguage"
                  v-model="form.isSameLanguage"
                  >ใช้เหมือนกันทุกภาษา
                </b-form-checkbox>
              </div>
            </b-col>
            <b-col class="text-right">
              <div class="panel">
                <b-button
                  type="button"
                  class="btn btn-language"
                  v-for="(language, index) in languageList"
                  v-bind:key="index"
                  v-bind:class="[languageActive == language.id ? 'active' : '']"
                  @click="changeLanguage(language.id, index)"
                  :disabled="form.isSameLanguage ? true : false"
                >
                  <span class="text-uppercase">{{ language.nation }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-1 mb-3">
            <b-col md="6">
              <div class="map">
                <GmapMap id="map" class="column" :center="center" :zoom="13">
                  <GmapMarker :position="center" :clickable="true" />
                </GmapMap>
              </div>
            </b-col>
            <b-col md="6">
              <InputText
                textFloat="ละติจูด"
                placeholder="ละติจูด"
                type="text"
                name="lat"
                isRequired
                :isValidate="$v.form.lat.$error"
                :v="$v.form.lat"
                v-model="form.lat"
              />
              <InputText
                textFloat="ลองจิจูด"
                placeholder="ลองจิจูด"
                type="text"
                name="lng"
                isRequired
                :isValidate="$v.form.long.$error"
                :v="$v.form.long"
                v-model="form.long"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <div
                v-for="(item, index) in form.translationList"
                v-bind:key="index"
              >
                <div
                  v-bind:class="[
                    languageActive == item.languageId ? '' : 'd-none',
                  ]"
                >
                  <InputText
                    v-if="item.contactUsAddress != null"
                    textFloat="ชื่อที่อยู่"
                    :value="item.contactUsAddress"
                    :name="'contactUsAddress_' + item.languageId"
                    type="text"
                    :img="imageLogoLang"
                    placeholder="ชื่อที่อยู่"
                    isRequired
                    :v="
                      $v.form.translationList.$each.$iter[index]
                        .contactUsAddress
                    "
                    @onDataChange="(val) => (item.contactUsAddress = val)"
                  />
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <InputText
                textFloat="เบอร์โทร"
                placeholder="เบอร์โทร"
                name="contactUsTelephone"
                :isValidate="$v.form.contactUsTelephone.$error"
                :v="$v.form.contactUsTelephone"
                v-model="form.contactUsTelephone"
                type="text"
                isRequired
              />
            </b-col>
            <b-col md="6">
              <InputText
                textFloat="อีเมล"
                placeholder="อีเมล"
                type="email"
                name="email"
                :isValidate="$v.form.contactUsEmail.$error"
                :v="$v.form.contactUsEmail"
                v-model="form.contactUsEmail"
                isRequired
              />
            </b-col>
            <b-col md="6">
              <InputText
                textFloat="แฟกซ์"
                placeholder="แฟกซ์"
                name="contactUsTelephone"
                :isValidate="$v.form.contactUsFax.$error"
                :v="$v.form.contactUsFax"
                v-model="form.contactUsFax"
                type="text"
                isRequired
              />
            </b-col>
            <b-col md="12">
              <div
                v-for="(item, index) in form.translationList"
                v-bind:key="index"
              >
                <div
                  v-bind:class="[
                    languageActive == item.languageId ? '' : 'd-none',
                  ]"
                >
                  <InputTextArea
                    v-if="item.aboutUsText != null"
                    rows="8"
                    textFloat="คำบรรยาย"
                    :value="item.aboutUsText"
                    :name="'aboutUsText_' + item.languageId"
                    :img="imageLogoLang"
                    placeholder="คำบรรยาย"
                    isRequired
                    :v="
                      $v.form.translationList.$each.$iter[index]
                        .aboutUsText
                    "
                    @onDataChange="(val) => (item.aboutUsText = val)"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <label class="font-weight-bold main-label mb-2">
                สถานะการแสดงผล Socials
              </label>
            </b-col>
          </b-row>
          <b-row class="mb-3 align-items-center">
            <b-col md="3">
              <b-form-checkbox
                switch
                v-model="form.isFacebook"
                class="radio-active"
              >
                <span class="ml-1 main-label"
                  >Facebook
                  <span class="text-danger" v-if="form.isFacebook"
                    >*</span
                  ></span
                >
              </b-form-checkbox>
            </b-col>
            <b-col md="9">
              <InputText
                placeholder="https://www.example.com"
                type="text"
                name="lng"
                isRequired
                :isValidate="$v.form.linkFacebook.$error"
                :v="$v.form.linkFacebook"
                v-model="form.linkFacebook"
                className="mb-0"
                :disabled="!form.isFacebook"
              />
            </b-col>
          </b-row>
          <b-row class="mb-3 align-items-center">
            <b-col md="3">
              <b-form-checkbox
                switch
                v-model="form.isInstagram"
                class="radio-active"
              >
                <span class="ml-1 main-label"
                  >Instagram
                  <span class="text-danger" v-if="form.isInstagram"
                    >*</span
                  ></span
                >
              </b-form-checkbox>
            </b-col>
            <b-col md="9">
              <InputText
                placeholder="https://www.example.com"
                type="text"
                name="lng"
                isRequired
                :isValidate="$v.form.linkInstagram.$error"
                :v="$v.form.linkInstagram"
                v-model="form.linkInstagram"
                className="mb-0"
                :disabled="!form.isInstagram"
              />
            </b-col>
          </b-row>
          <b-row class="mb-3 align-items-center">
            <b-col md="3">
              <b-form-checkbox
                switch
                v-model="form.isLine"
                class="radio-active"
              >
                <span class="ml-1 main-label"
                  >Line
                  <span class="text-danger" v-if="form.isLine">*</span></span
                >
              </b-form-checkbox>
            </b-col>
            <b-col md="9">
              <InputText
                placeholder="https://www.example.com"
                type="text"
                name="lng"
                isRequired
                :isValidate="$v.form.linkLine.$error"
                :v="$v.form.linkLine"
                v-model="form.linkLine"
                className="mb-0"
                :disabled="!form.isLine"
              />
            </b-col>
          </b-row>
          <b-row class="mb-3 align-items-center">
            <b-col md="3">
              <b-form-checkbox switch v-model="form.isX" class="radio-active">
                <span class="ml-1 main-label"
                  >X <span class="text-danger" v-if="form.isX">*</span></span
                >
              </b-form-checkbox>
            </b-col>
            <b-col md="9">
              <InputText
                placeholder="https://www.example.com"
                type="text"
                name="lng"
                isRequired
                :isValidate="$v.form.linkX.$error"
                :v="$v.form.linkX"
                v-model="form.linkX"
                className="mb-0"
                :disabled="!form.isX"
              />
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col class="text-sm-right">
              <button
                type="button"
                @click="checkForm()"
                :disabled="isDisable"
                class="btn btn-main btn-details-set ml-md-2 text-uppercase"
              >
                บันทึก
              </button>
            </b-col>
          </b-row>
        </form>
      </div>
    </b-container>
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import { required, email, requiredIf, decimal, url } from "vuelidate/lib/validators";
import InputTextArea from "@/components/inputs/InputTextArea";
import Vue from "vue";

export default {
  name: "AboutUs",
  components: {
    InputText,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
    InputTextArea,
  },
  data() {
    return {
      isEdit: false,
      isDisable: false,
      isSuccess: false,
      id: this.$route.params.id,
      modalMessage: "",
      center: { lat: 13.719559, lng: 100.585058 },
      imageLogoLang: "",
      languageActive: 1,
      languageList: [],
      form: {
        contactUsTelephone: "",
        contactUsFax: "",
        contactUsEmail: "",
        isFacebook: false,
        isInstagram: false,
        isLine: false,
        isX: false,
        lat: "",
        long: "",
        linkFacebook: "",
        linkInstagram: "",
        linkLine: "",
        linkX: "",
        mainLanguageId: 1,
        isSameLanguage: true,
        translationList: [
          {
            languageId: 1,
            contactUsAddress: "",
            aboutUsText: "",
          },
          {
            languageId: 2,
            contactUsAddress: "",
            aboutUsText: "",
          },
        ]
      },
    };
  },
  validations: {
    form: {
      contactUsTelephone: { required },
      contactUsFax: { required },
      contactUsEmail: {
        email,
      },
      lat: { required, decimal },
      long: { required, decimal },
      linkFacebook: {
        required: requiredIf(function (item) {
          return item.isFacebook;
        }),
        url
      },
      linkInstagram: {
        required: requiredIf(function (item) {
          return item.isInstagram;
        }),
        url
      },
      linkLine: {
        required: requiredIf(function (item) {
          return item.isLine;
        }),
        url
      },
      linkX: {
        required: requiredIf(function (item) {
          return item.isX;
        }),
        url
      },
      translationList: {
        $each: {
          contactUsAddress: { required },
          aboutUsText: { required },
        },
      },
    },
  },
  created: async function () {
    this.$isLoading = false;
    await this.getLanguage();
    await this.getData();
  },
  watch: {
    "form.lat": async function (e) {
      if (e) this.center.lat = parseFloat(e.toString().replace(/,/g, ""));
    },
    "form.long": async function (e) {
      if (e)  this.center.lng = parseFloat(e.toString().replace(/,/g, ""));
    },
  },
  methods: {
    getLanguage: async function () {
      let languages = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/language`,
        null,
        this.$headers,
        null
      );

      if (languages.result == 1) {
        this.languageList = languages.detail;
      }
    },
    getData: async function () {
      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/ContactUs/ConfigFooter`,
        null,
        this.$headers,
        null
      );

      if (data.result == 1) {
        this.form = {...data.detail};
        this.$v.form.$reset();

        if (this.form.isSameLanguage) {
          this.imageLogoLang = "";
          this.languageActive = this.form.mainLanguageId;
        } else {
          var index = this.languageList
            .map(function (x) {
              return x.id;
            })
            .indexOf(this.languageActive);
          this.imageLogoLang = this.languageList[index].imageUrl;
        }
      }
      this.$isLoading = true;
    },
    checkForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      await this.submit();
    },
    changeLanguage(id, index) {
      if (!this.form.isSameLanguage) {
        this.languageActive = id;
        this.imageLogoLang = this.languageList[index].imageUrl;
      }
    },
    useSameLanguage: async function () {
      Vue.nextTick(() => {
        if (this.form.isSameLanguage) {
          this.imageLogoLang = "";
          this.form.mainLanguageId = this.languageActive;
          let data = this.form.translationList.filter(
            (val) => val.languageId == this.form.mainLanguageId
          );

          if (this.id == 0) {
            if (data.length == 1) {
              data = data[0];
              for (
                let index = 0;
                index < this.form.translationList.length;
                index++
              ) {
                this.form.translationList[index].contactUsAddress = data.contactUsAddress;
                this.form.translationList[index].aboutUsText =
                  data.aboutUsText;
              }
            }
          }
        } else {
          var index = this.languageList
            .map(function (x) {
              return x.id;
            })
            .indexOf(this.languageActive);
          this.imageLogoLang = this.languageList[index].imageUrl;

          let data = this.form.translationList.filter(
            (val) => val.languageId != this.form.mainLanguageId
          );

          if (this.id == 0) {
            if (data.length == 1) {
              data = data[0];
              data.contactUsAddress = "";
              data.aboutUsText = "";
            }
          }
        }
      });
    },
    checkValidateTranslationList: async function () {
      let isError = false;
      this.languageList.forEach((element, index) => {
        if (!isError) {
          if (
            this.$v.form.translationList.$each.$iter[index].$error
          ) {
            this.languageActive =
              this.$v.form.translationList.$model[index].languageId;

            this.imageLogoLang = this.languageList[index].imageUrl;

            isError = true;
          }
        }
      });
    },
    submit: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();
      let data = await this.$callApi(
        "put",
        `${this.$baseUrl}/api/ContactUs/ConfigFooter`,
        null,
        this.$headers,
        this.form
      );

      this.$refs.modalLoading.hide();
      this.modalMessage = data.detail;
      this.isDisable = false;

      if (data.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();

        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
        this.getData();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>

<style scoped>
.vue-map-container {
  width: 100%;
  height: 450px;
}

.text-error {
  color: #ff0000 !important;
  font-size: 14px;
  margin-top: -14px;
}

@media (max-width: 1200px) {
  .vue-map-container {
    height: 320px;
  }
}

@media (max-width: 767px) {
  .form {
    margin-top: 10px;
  }
}
</style>
